.contacts-group {
    display: flex;
    flex-wrap: wrap;
    padding: 2em .25em;
    justify-content: baseline;
}

.contact {
    position: relative;
    width: 6em;
    height: 6em;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    border-radius: 50%;
    background-color: #efefef;
    border: 2px solid #c0c0c0;

    margin: .25em;
    cursor: pointer;
    user-select: none;
    touch-action: none;
}

.contact-shadow {
    opacity: 0;
    pointer-events: none;
    transition: opacity ease-in 0.1s;

    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.5);
}

.contact.active { z-index: 2; }
.contact.active + .contact-shadow {
    opacity: 1;
    z-index: 1;
}

.contact-button {
    height: 4em;
    width: 100%;

    display: none;
    justify-content: center;
    align-content: center;
    align-items: center;

    position: absolute;
    left: 0;
}

.contact-button svg {
    width: 2em;
    height: 2em;
    pointer-events: none;
}

.contact.active > .contact-button {
    display: flex;
}

.contact-listen {
    top: -4em;
}

.play-reminder {
    width: 2em;
    height: 2em;
    pointer-events: none;

    position: absolute;
    top: 0;
    left: calc(50% - 1em);

    display: flex;
    justify-content: center;
    align-items: center;
}
.play-reminder svg { width: 60%; height: 60%;}

.contact-record {
    bottom: -4em;
}