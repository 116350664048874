.login-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
}

.login-section {
    display: inline-flex;
    flex-direction: column;
}