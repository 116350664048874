.chitalib-messagebox-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: rgba(200,200,200, 0.5);
    z-index: 99;
    font-size: 1rem;
}

.chitalib-messagebox {
    background-color: white;
    border: 1px solid #e8e8e8;

    padding: 0 1rem;
    border-radius: 2px;
    width: 20em;

    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);

    user-select: none; 
}

.chitalib-messagebox-title {
    font-weight: bold;
    margin: 1rem 0;
}

.chitalib-messagebox-desc {
    margin: 1rem 0;
}

.chitalib-messagebox-button {
    margin: .5rem 0;
}

.chitalib-messagebox-button input {
    appearance: none;
    width: 100%;
    height: 100%;
    background-color: #ccc;
    border: none;
    padding: 1rem 0;
    font-size: 1em;
    font-weight: 600;
}